import {createContext, useState, useEffect} from 'react' ;

const ProductDataContext = createContext() ;

const rearrangeArrayBySno = (originalArray) => {
    const resultArray = Array(50).fill(null);

    originalArray.forEach((item) => {
        const { sno } = item;
        if (sno > 0)
            resultArray[sno] = item;
    });

    return resultArray;
};

const ProductDataContextProvider = ({children}) => {
	const [productData, setProductData] = useState([]) ;

	useEffect( () => {
		fetch('https://api.myarthhardware.com/product')
		.then(res => {
			if(res.ok)
				return res.json() ;
			throw Error(res.statusText) ;
		})
		.then( data => {
			setProductData(rearrangeArrayBySno(data)) ;
		}) 
		.catch( err  => console.log(err) ) ;
	}, []) ;

	return (
		<ProductDataContext.Provider value={ { data: productData } }>
			{children}
		</ProductDataContext.Provider>
	) ;
}

export {ProductDataContext, ProductDataContextProvider} ;